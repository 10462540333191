<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { currency } from "@/config/api/currency";
import CurrencyModal from "@/components/currency/currency-modal";
/**
* Advanced table component
*/
export default {
  page: {
    title: "Currency",
    meta: [{ name: "Currency", content: appConfig.description }],
  },
  components: { Layout, PageHeader, CurrencyModal, },
  data() {
    return {
      title: "Currency",
      breadcrumbs: [
        {
          text: "Currency",
          active: true,
        },
        {
          text: "home",
          to: { name: "home" },
        },
      ],
      currencyData: [],
      selectedCurrency: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activeTab: null,
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "name", sortable: false },
        { key: "abbrv", sortable: false },
        { key: "country", sortable: false },
        { key: "createdAt", sortable: false },
        { key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.currencyData.length;
    },
    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
    deletedCountries() {
      return this.currencyData.filter((email) => email.deletedAt != null);
    },
    activeCountries() {
      return this.currencyData.filter((email) => email.deletedAt === null);
    },
  },
  beforeMount() {
    this.loadData();
  },
  mounted() {
    this.totalRows = this.currencyData.length;
  },
  methods: {

    async loadData() {
      this.isBusy = true;
      const api = currency.getAll;
      await this.generateAPI(api)
        .then((res) => {

          this.currencyData = res.data.currencies;
        })
        .catch((err) => {
          console.error(err.response.data);
        }).finally(() => {
          this.isBusy = false;
        });
    },

    editCountry(data) {
      this.selectedCurrency = data;
      this.showActionModal();
    },
    deleteCountry(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = currency.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Currency deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadData();
              })
              .catch((err) => {
                this.$bvToast.toast("Currency is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    showActionModal() {
      this.$bvModal.show("action-Currency");
    },
    hideActionModal() {
      this.$bvModal.hide("action-Currency");
    },
    addCountry() {
      this.selectedCurrency = null;
      this.showActionModal();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button id="addCountries" @click="addCountry" pill variant="primary" class="mb-2">Add Currency</b-button>
            <!-- Table -->
            <div class="table-responsive mt-">
              <b-table :items="currencyData" :fields="fields" responsive="sm" :busy="isBusy" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #table-busy>
                  <div style="text-align: center" class="mt-5 mb-5">
                    <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                  </div>
                </template>
                <template #cell(actions)="row">
                  <button class="action-btn" @click="deleteCountry(row.item)">
                    <i class="ri-delete-bin-line"></i>
                  </button>
                  <button class="action-btn" @click="editCountry(row.item)">
                    <i class="ri-edit-line"></i>
                  </button>
                </template>
                <template #cell(createdAt)="row">
                  {{ moment(row.item.createdAt).format("LL") }}
                </template>
              </b-table>
            </div>

          </div>
        </div>
      </div>
    </div>
    <CurrencyModal :selected="selectedCurrency" v-on:resetModal="selectedCurrency = null"
      v-on:reloadData="loadData" v-on:closeModal="hideActionModal" />
  </Layout>
</template>

<style scoped>
#addCountries {
  float: right;
  margin-top: 10px;
}
</style>