<script>
import { currency } from "@/config/api/currency";
export default {
  props: ["selected"],
  data() {
    return {
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
        {
          index: 0,
          type: "text",
          name: "abbrv",
          label: "Abbreviation",
          validation: "required",
        },
        {
          index: 0,
          type: "text",
          name: "country",
          label: "Country",
          validation: "required",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) { 
      if (this.selected) {
        this.updateCurrency(data, this.selected._id);
      } else {
        this.addCurrency(data);
      }
    },
    addCurrency(data) {
      this.performAction = true;
      const api = currency.create;
      api.data = data;
      this.generateAPI(api)
        .then(() => {
          this.$formulate.reset("CurrencyAction");
          this.$bvToast.toast("Currency added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Currency is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateCurrency(data, id) {
      this.performAction = true;
      const api = currency.update;
      api.data = data;
      api.id = id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Currency updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Currency is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
          this.$emit("closeModal");
        });
    },
  },
  watch: {
    selected(val) { 
      if (val) {
        this.values = { ...val };
      }
    },
  },
};
</script>

<template>
  <b-modal
    id="action-Currency"
    @hidden="$emit('resetModal')"
    :title="selected ? 'Update Currency' : 'Add Currency'"
    hide-footer
  >
    <FormulateForm
      name="CurrencyAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selected"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selected"> Update </span>
          <span v-else> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>
